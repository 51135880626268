<template>
  <the-new-header>
    <template v-slot:main-block>&nbsp;</template>
  </the-new-header>
  <div class="page-content plan-selection-page">
    <main class="full-width">
      <div class="new-container">
        <h1 class="page-title">Maximize your app's growth with Asolytics. Choose your plan and start your journey.</h1>

        <subscribe-plans>&nbsp;</subscribe-plans>

      </div>
    </main>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SubscribePlans from "@/components/Modals/SubscribePlans";
import {deleteCookie} from "@/utils/utils";

export default {
  name: 'PlanSelectionPage',
  components: {
    'subscribe-plans': SubscribePlans,
  },
  data() {
    return {
      loaded: false,
      env: process.env.VUE_APP_URL,
      title: '',
    }
  },
  created() {
    if (this.userData?.is_confirmed_some_plan === true) {
      this.$router.push('/');
    } else {
      deleteCookie('currentAppId');
    }
  },
  computed: {
    ...mapGetters([
      'userData',
      'isMobile',
      'sidebarIsCollapsed'
    ]),
    hasSubscription() {
      return this.userSubscription?.subscriber;
    },
    subscriptionLink() {
      return this.userSubscription?.url;
    },
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>